<template>
  <div class="cloudservices">
    <img src="../../assets/product/cloudservices/1.png" class="headerimg" />
    <div class="serve">
      <div class="title">邦伲德全栈云服务</div>
      <div>
        <div class="servetitle2">
          <div class="headline">IoT联接服务</div>
          <div class="headline2">实现设备的统一接入和管理</div>
        </div>
        <div class="servelist">
          <div class="card" v-for="(item, index) in servelist" :key="index">
            <img :src="item.imgurl" />
            <div class="cardtext">
              {{ item.title
              }}<span :style="{ background: item.color }">端</span>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: space-between">
        <div>
          <div class="servetitle2">
            <div class="headline">行业使能服务</div>
            <div class="headline2">基于行业实践经验,提供设备接入和管理体验</div>
          </div>
          <div class="servelist">
            <div class="card">
              <img src="../../assets/product/cloudservices/7.png" />
              <div class="cardtext">
                IoT数据分析<span style="background: #2f7ffc">云</span>
              </div>
            </div>
          </div>
        </div>
        <div class="info">
          <div class="servetitle2">
            <div class="headline">数据分析服务</div>
            <div class="headline2">处理和分析物联网数据，实现数据快速变现</div>
          </div>
          <div class="servelist list2">
            <div class="card">
              <img src="../../assets/product/cloudservices/8.png" />
              <div class="cardtext">
                IoT开发者服务<span style="background: #2f7ffc">云</span>
              </div>
            </div>
            <div class="card">
              <img src="../../assets/product/cloudservices/9.png" />
              <div class="cardtext">
                车联网服务<span style="background: #2f7ffc">云</span>
              </div>
            </div>
            <div class="card">
              <img src="../../assets/product/cloudservices/10.png" />
              <div class="cardtext">
                路网数字化服务<span style="background: #2f7ffc">云</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="case">
      <div class="title" style="color: #fff">邦伲德IoT解决方案</div>
      <div class="caselist">
        <div
          class="caseinfo"
          v-for="(item, index) in caselist"
          :key="index"
          :style="{ backgroundImage: 'url(' + item.imgurl + ')' }"
        >
          <div class="casetext">{{ item.title }}</div>
        </div>
      </div>
    </div>

    <div class="why">
      <div class="title">为什么选择邦伲德IoT?</div>
      <div class="servetitle2">
        <div class="headline">技术优势</div>
      </div>
      <div class="whylist">
        <div style="display: flex; justify-content: space-between">
          <div class="card">
            <img src="../../assets/product/cloudservices/16.png" />
            <div class="cardtext">安全可信的万物互联</div>
          </div>
          <div class="card">
            <img src="../../assets/product/cloudservices/17.png" />
            <div class="cardtext">多场景的体验</div>
          </div>
          <div class="card">
            <img src="../../assets/product/cloudservices/18.png" />
            <div class="cardtext">高效的物联网智能</div>
          </div>
          <div class="card">
            <img src="../../assets/product/cloudservices/19.png" />
            <div class="cardtext">开放的物联网生态</div>
          </div>
        </div>
      </div>
    </div>
    <div class="why">
      <div class="title">为什么选择邦伲德IoT?</div>

      <div class="whylist">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
          class="whylist2"
        >
          <div>
            <div class="servetitle2">
              <div class="headline">智慧交通</div>
            </div>
            <div class="whytext">
              基于邦伲德IoT提供的路网数字化服务，都汶高速采用摄像头、雷达等多维度数据采集，在不受雨雾遮挡影响、不新增路灯的情况下，实现全天候实时感知，并能对车辆位置、速度等进行准确检测。同时，将310+通用交通事件和都汶龙池路段的复杂路况相结合，实现多种车路协同场景的安全预警。
            </div>
          </div>
          <div class="whyimg">
            <img src="../../assets/product/cloudservices/20.png" />
          </div>
        </div>
      </div>
    </div>

    <div class="cooperation">
      <div class="title">生态合作伙伴及企业客户</div>
      <div class="titlel2">基于行业实践经验,提供最佳设备接入和管理体验</div>
      <img src="../../assets/product/cloudservices/21.png" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      servelist: [
        {
          title: "LiteOS",
          typename: "端",
          color: "#E02020",
          imgurl: require("../../assets/product/cloudservices/2.png"),
        },
        {
          title: "IoT边缘",
          typename: "边",
          color: "#FFDA0A",
          imgurl: require("../../assets/product/cloudservices/3.png"),
        },
        {
          title: "设备接入",
          typename: "云",
          color: "#2F7FFC",
          imgurl: require("../../assets/product/cloudservices/4.png"),
        },
        {
          title: "设备发放",
          typename: "云",
          color: "#2F7FFC",
          imgurl: require("../../assets/product/cloudservices/5.png"),
        },
        {
          title: "全球SIM联接",
          typename: "云",
          color: "#2F7FFC",
          imgurl: require("../../assets/product/cloudservices/6.png"),
        },
      ],
      caselist: [
        {
          title: "智慧城市",
          imgurl: require("../../assets/product/cloudservices/11.png"),
        },
        {
          title: "智慧园区",
          imgurl: require("../../assets/product/cloudservices/12.png"),
        },
        {
          title: "车联网",
          imgurl: require("../../assets/product/cloudservices/13.png"),
        },
        {
          title: "智能交通",
          imgurl: require("../../assets/product/cloudservices/14.png"),
        },
        {
          title: "智慧仓储",
          imgurl: require("../../assets/product/cloudservices/15.png"),
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.cloudservices {
  .title {
    text-align: center;
    font-size: 36px;
    font-weight: 500;
    line-height: 54px;
  }

  .servetitle2 {
    border-left: 10px solid #2f7ffc;
    padding-left: 10px;
    margin-top: 80px;

    .headline {
      font-size: 36px;
      font-weight: 500;
      line-height: 54px;
    }

    .headline2 {
      font-size: 24px;
      font-weight: 500;
      color: #616b80;
      line-height: 36px;
    }
  }

  .serve {
    margin: 0 auto;
    padding: 140px 0;
    width: 80%;

    .servelist {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      .card {
        width: 270px;
        height: 270px;
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
        padding: 43px 0;
        box-sizing: border-box;
        text-align: center;

        img {
          width: 80px;
          height: 92px;
        }

        .cardtext {
          font-size: 30px;
          font-weight: 500;
          line-height: 45px;
          margin-top: 45px;

          span {
            padding: 8px;
            border-radius: 4px;
            font-size: 24px;
            margin-left: 12px;
            color: #fff;
          }
        }
      }
    }

    .list2 {
      width: 904px;
    }
  }

  .case {
    background: #262730;
    padding: 80px;

    .caselist {
      margin-top: 42px;
      display: flex;
      justify-content: center;

      .caseinfo {
        width: 282px;
        height: 360px;
        background-image: url("../../assets/product/cloudservices/11.png");
        background-size: 100% 100%;
        box-sizing: border-box;

        .casetext {
          padding-left: 26px;
          padding-bottom: 26px;
          font-size: 36px;
          font-weight: 500;
          color: #ffffff;
          line-height: 54px;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.51) 51%,
            #000000 100%
          );
          margin-top: 290px;
        }
      }
    }
  }

  .why {
    margin: 0 auto;
    margin-top: 140px;
    width: 80%;

    .whylist {
      margin-top: 30px;

      .whyimg {
        img {
          width: 574px;
          height: 328px;
        }
      }
      .whylist2 {
        margin-top: 80px;
        padding-bottom: 137px;
        .title2 {
          margin-top: 0;
        }
      }
      .whytext {
        width: 768px;
        margin-top: 51px;
        color: #616b80;
        font-size: 24px;
        line-height: 48px;
      }
      .card {
        width: 325px;
        height: 325px;
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
        padding: 71px 0;
        box-sizing: border-box;
        text-align: center;

        img {
          width: 80px;
          height: 92px;
        }

        .cardtext {
          font-size: 30px;
          font-weight: 500;
          line-height: 45px;
          margin-top: 45px;
        }
      }
    }
  }

  .cooperation {
    background: #f5f5f9;
    padding: 90px 0;
    text-align: center;
    .titlel2 {
      font-size: 24px;
      font-weight: 400;
      color: #616b80;
      line-height: 48px;
    }
    img {
      margin-top: 80px;
      width: 1100px;
    }
  }
}
</style>
